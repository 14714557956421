<template>
  <div>Верификация</div>
</template>

<script>
export default {
  async created() {
    let { code } = this.$route.params;
    try {
      let { data } = await this.$http.get("user/verify/" + code);
      this.$toast.success(data.response.message);
      this.$router.push("/");
    } catch (error) {
      this.$toast.error(error?.message || "Ошибка отправки запроса");
    }
  },
};
</script>
